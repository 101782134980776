<template>
  <div class="question">
    <p class="question-statement">{{ statement }}</p>

    <div class="alternatives-container">
      <div class="alternatives-group">
        <button
          v-for="alternative in alternatives"
          @click="$emit('selectAlternative', alternative.value)"
          :key="alternative.id"
          class="alternative"
        >
          <div class="img-alternative">
            <img
              :src="iconImage(alternative.statement)"
              :class="{ selected: isAlternativeSelected(alternative.id) }"
            />
          </div>
          <div class="desc-alternative">
            <p>{{ alternative.statement }}</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statement: {
      type: String,
      required: true,
    },
    alternatives: {
      type: Array,
      required: true,
    },
    selectedAlternative: {
      type: String,
      default: "",
    },
  },
  emits: ["selectAlternative"],
  methods: {
    iconImage(statement) {
      let image = "";
      switch (statement) {
        case "Discordo Totalmente":
          image = require("@/assets/icons/discordo-totalmente.png");
          break;
        case "Discordo":
          image = require("@/assets/icons/discordo.png");
          break;
        case "Discordo Parcialmente":
          image = require("@/assets/icons/discordo-parcialmente.png");
          break;
        case "Concordo Parcialmente":
          image = require("@/assets/icons/concordo-parcialmente.png");
          break;
        case "Concordo":
          image = require("@/assets/icons/concordo.png");
          break;
        case "Concordo Totalmente":
          image = require("@/assets/icons/concordo-totalmente.png");
          break;
        default:
          image = require("@/assets/icons/discordo-totalmente.png");
      }

      return image;
    },

    isAlternativeSelected(alternativeID) {
      return this.selectedAlternative === alternativeID;
    },
  },
};
</script>

<style scoped>
.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 2.5em;
  z-index: 5;
}

.question-statement {
  font-size: 28px;
  height: 5.5em;
  color: #000;
  text-align: center;
  width: 100%;
}

.alternatives-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.alternatives-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 1em;
}

.alternative {
  width: 6rem;
  height: 8rem;
  display: flex;
  font-family: "League Spartan";
  font-size: 1.3em;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  flex: 0 1 calc(16.66% - 10px);
  border: none;
  color: #ea7b08;
}

.img-alternative {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-alternative img {
  min-width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(3px 5px 5px #00000040);
  transition: all 0.2s;
}

.selected {
  transform: translateY(-15px);
  box-shadow: 0px 0px 15px 5px #fb820969;
  filter: none;
  border-radius: 100%;
  transition: all 0.2s;
}

.desc-alternative {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px #00000040;
}

.desc-alternative p {
  text-align: center;
  font-weight: 600;
}

.alternatives-group button {
  cursor: pointer;
  transition: all 0.2s;
}

.alternatives-group button:hover {
  transform: scale(1.1);
  transition: all 0.2s;
}

@media screen and (min-width: 810px) {
  .question-statement {
    height: 3.5em;
  }

  .alternatives-group {
    column-gap: 3em;
    row-gap: 2em;
  }
}

@media screen and (min-width: 1080px) {
  .question-statement {
    font-size: 2em;
  }

  .alternatives-group {
    gap: 0em;
  }
}
</style>
