<template>
    <LoadingComponent class="loading" v-if="this.loading" />
    <div id="profile">
        <section>
            <div class="head">
                <div class="name-photo-container">
                    <NameContainer :name="this.profile.name"/>
                    <ProfilePhoto :Image="this.profile.photo" />
                </div>
                <UserDataContainer :age="this.profile.age" :gender="this.profile.gender" />
            </div>
            <div class="products">
                <ProductCard v-for="product in profile.products" :key="product.productId" :nome="product.name"
                    :blocked="product.blocked" :id-user="profile.idUser" :id-quiz="product.assets[0]"
                    :accessedAt="accessedAt" :finished="finished" />
                <ProductCard nome="Sabotadores" :id-user="profile.idUser" id-quiz="0" :blocked="true" :accessedAt="null"
                    :status="status" />
                <ProductCard :blocked="true" :id-user="profile.idUser" id-quiz="0" :accessedAt="null"
                    :status="status" />
            </div>
        </section>
    </div>
</template>

<script>
import ProfilePhoto from '@/components/Profile/ProfilePhoto.vue';
import ProductCard from '@/components/Profile/ProductCard.vue'
import UserDataContainer from '@/components/Profile/UserDataContainer.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import NameContainer from '@/components/Profile/NameContainer.vue';

import router from '@/router';
import axios from 'axios'

export default {
    components: {
        ProfilePhoto,
        ProductCard,
        LoadingComponent,
        NameContainer,
        UserDataContainer,
    },

    data() {
        return {
            profile: {},
            loading: true,
            accessedAt: null,
            finished: false,
        };
    },

    methods: {
        async getProfile(idUser) {
            const token = localStorage.getItem("userJWT");

            try {
            const response = await axios.post(
                `${process.env.VUE_APP_BASE_URL}/profile/${idUser}`,
                {},
                {
                headers: {
                    Authorization: token,
                },
                });
            if (response && response.data) {
                this.profile = response.data;
                this.loading = false;
                this.accessedAt = this.profile.products[0].accessedAt;
                this.finished = this.profile.status == 'finished' ? true : false;
                
                localStorage.setItem('idUser', this.profile.idUser);
            }
            } catch (error) {
                if (error.response?.data.status === 403) {
                    router.push(`/profile/${error.response.data.expectedIdUser}`);
                }
                else if (error.response?.data.status === 401) {
                    router.push({path: "/", query: {"expired": true}});
                }
                else {
                    router.push("/error/system-failure");
                }
            }
        }
    },

    async mounted(){
        document.body.style.background = "#ea7b08"
        const idUser = this.$route.params.idUser
        await this.getProfile(idUser)   
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.name-photo-container{
    width: 100%;
    height:70%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between; 
}

.head {
    width: 100%;
    height: 330px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 30px 30px 0 0;
    padding-inline: 2rem;
}

 section {
    height: auto;
    width: 200vh;
    border-radius: 30px 30px 30px 30px;
    background: white;
    margin-bottom: 10px;
    margin-inline: 2rem;
}

.products {
    width: 100%;
    height: 45%;
    padding-top: 40px;
    background-color: #EAEAEA;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 0px 0px 30px 30px;
    padding-inline: 2rem;
}

html{
    background-color: #ea7b08
}

#profile {
    height: auto;
    background-color: #ea7b08;
    display: flex;
    padding-top: 10em;
    justify-content: center;
    align-items: start;
    overflow-y: scroll;
}

@media (min-width: 820px){
    .head{
        width: 100%;
        height: 330px;
        flex-direction: row;
        padding-top: 40px;
    }

    .name-photo-container{
        flex-direction: row;
    }

    .products {
        flex-direction: row;
        justify-content: space-evenly;
        gap: 10px;
        min-height: 100%;
    }

    section{
        min-height: 90%;
    }

    #profile{
        height: 100vh;
    }

}

/* ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd */

/* section {
    height: 80%;
    width: 90%;
    border-radius: 60px 60px 0 0;
    background: #f3b471b7;

}

.mobile-view {
    display: none;
}

.name-insignia {
    width: 33%;
    padding-left: 1.3em;
}

.name {
    color: white;
    font-family: "League Spartan", sans-serif;
    font-size: 1.6em;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    line-height: 1.5em;
    text-shadow: 2px 2px 4px rgba(252, 126, 0, 0.836);
}

@media (max-width: 1200px) {
    .name {
        font-size: 1.2em;
    }
}

.insignia-container {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

}

.user-photo {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}


.user-data {
    display: flex;
    justify-content: start;
    align-items: end;
    flex-direction: column;
    margin-top: 2.5em;
    gap: 1em;
    color: white;
    font-family: "League Spartan", sans-serif;
    font-size: 2.4em;
    font-weight: 600;
    width: 33%;
    padding-right: 1.3em;
    text-shadow: 2px 2px 4px rgba(252, 126, 0, 0.836)
}

.insignia {
    height: 6em;
    margin-right: 0.5em;
}

@media (max-width: 1300px) {
    .user-data {
        font-size: xx-large;
    }

    .insignia {
        height: 5em;
    }
}

@media (max-width: 1150px) {
    .insignia {
        height: 4em;
    }
}

@media (max-width: 992px) {
    .user-data {
        font-size: x-large;
        justify-content: center;
        gap: 3em;
    }

    .insignia {
        height: 3.3em;
    }
}

@media (max-width: 768px) {
    .insignia {
        width: 3em;
        height: 3em;
    }
}

@media (max-width: 650px) {
    #profile {
        height: auto;
    }

    section {
        height: 90%;
        margin-top: 10em;
    }

    .mobile-view {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .head {
        display: none;
    }

    .user-photo {
        height: 7em;
        width: 100%;
    }

    .name-insignia {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 33%;
        width: 100%;
    }

    .products {
        height: 100%;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    .name {
        justify-content: center;
        align-items: center;
        height: 5em;
        margin-top: 2em;
    }

    .insignia-container {
        justify-content: center;
        height: 4em;
    }

    .insignia {
        height: 3em
    }

    .user-data {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 0.6em;
        width: 100%;
        height: 33%;
        margin-block: 1em;
        padding: 0;
    }
} */
</style>
