<template>
  <div class="affinity-statements">
    <div class="desc">
      <h1>
        {{ title }}
      </h1>
    </div>

    <StatementCard
      v-for="statement in statements"
      :key="statement.id"
      :statement="statement.statement"
      @select="selectStatement(statement.id)"
      :blocked="isBlocked(statement.id)"
    />
  </div>
</template>

<script>
import StatementCard from "./StatementCard.vue";

export default {
  data() {
    return {
      selectedStatements: [],
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    statements: {
      type: Array,
      required: true,
    },
    maxRequired: {
      type: Number,
      default: null,
    },
  },
  components: {
    StatementCard,
  },
  emits: ["selectResponses"],
  methods: {
    selectStatement(statementID) {
      const index = this.selectedStatements.indexOf(statementID);
      if (index === -1) {
        this.selectedStatements.push(statementID);
      } else {
        this.selectedStatements.splice(index, 1);
      }

      this.emitResponses();
    },

    isBlocked(statementID) {
      if (!this.maxRequired) {
        return false;
      }

      return (
        this.selectedStatements.length >= this.maxRequired &&
        !this.selectedStatements.includes(statementID)
      );
    },

    emitResponses() {
      this.responses = [];

      this.selectedStatements.forEach((statementID) => {
        const statement = this.statements.find((st) => st.id === statementID);

        if (statement) {
          const existingResponse = this.responses.find(
            (response) => response.theme === statement.theme
          );

          if (existingResponse) {
            existingResponse.value += 1;
            return;
          }

          this.responses.push({
            value: 1,
            theme: statement.theme,
          });
        }
      });

      this.$emit("selectResponses", this.responses);
    },
  },
};
</script>

<style scoped>
.affinity-statements {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  width: 100%;
  padding-bottom: 2em;
  z-index: 5;
}

.affinity-statements .desc {
  color: #000000;
  font-size: 0.75em;
  padding-inline: 0.5em;
  text-align: center;
  font-family: "League Spartan";
}

@media screen and (min-width: 810px) {
  .affinity-statements .desc {
    font-size: 0.9em;
  }
}
</style>
