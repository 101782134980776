<template>
  <div class="progress-bar">
    <div
      class="progress-bar__fill"
      :style="{ width: progressPercentage + '%' }"
      role="progressbar"
      :aria-valuenow="progressPercentage"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
  },
  computed: {
    progressPercentage() {
      return this.value;
    },
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 1.5em;
  background-color: #e6e6e6;
  border-radius: 10px;
  border: solid 2px #e6e6e6;
  overflow: hidden;
  position: relative;
}

.progress-bar__fill {
  height: 100%;
  background-color: #ea7b08;
  transition: width 0.3s ease;
  border-radius: 10px 0 0 10px;
}
</style>
