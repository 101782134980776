<template>
  <div class="generic-button">
    <button :disabled="!isValid" :type="type">
      {{ text }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: 'Enviar',
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: 'submit',
      },
    },
  };
</script>

<style scoped>
.generic-button {
  height: 50px;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generic-button button {
  height: 100%;
  width: 100%;
  font-size: 20px;
  color: white;
  background-color: #298487;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 18px;
  transition: 0.2s ease;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.generic-button button:hover {
  scale: 1.03;
  cursor: pointer;
}

.generic-button button:active {
  scale: 1;
}

.generic-button button:disabled {
  background: #989898;
  transition: none;
  scale: 1;
  cursor: default;
}
</style>