<template>
  <div
    class="statement-card"
    :class="{ selected: isSelected, blocked: blocked }"
    @click="onClick"
  >
    <p>
      {{ statement }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSelected: false,
    };
  },
  props: {
    statement: {
      type: String,
      required: true,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  methods: {
    onClick() {
      if (this.blocked) return;
      this.isSelected = !this.isSelected;
      this.$emit("select", this.isSelected);
    },
  },
};
</script>

<style scoped>
.statement-card {
  width: 100%;
  display: flex;
  min-height: 8em;
  justify-content: left;
  align-items: center;
  padding: 2em 2em;
  background: #bcbcbc41;
  border-radius: 50px;
  transition: transform ease 0.2s;
}

.statement-card p {
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
}

.statement-card:hover {
  transform: translateY(-3px);
  cursor: pointer;
}

.selected {
  box-shadow: -10px 5px 3px #EA7B08;
}

.blocked {
  background: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.6;
}

@media screen and (min-width: 1080px) {
  .statement-card p {
    text-align: left;
  }
}
</style>
